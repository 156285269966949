import "./pricingpackageslist.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePricingPackage, getPricingPackages } from "../../redux/apiCalls";
import { Link } from "react-router-dom";
import {InfinitySpin } from 'react-loader-spinner';
export default function PricingPackageList() {
  const dispatch = useDispatch();
  const { packages: pricingPackages, isFetching, error } = useSelector((state) => state.pricingPackage);


  useEffect(() => {
    getPricingPackages(dispatch);
  }, [dispatch]);

  const handleDelete = (id) => {
    deletePricingPackage(id, dispatch);
  };

  const columns = [
    { 
      field: "name", 
      headerName: "Package Name",
      width: 150,
      flex: 1
    },
    {
      field: "features",
      headerName: "Features",
      width: 300,
      flex: 2,
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: 'normal', lineHeight: '1.2' }}>
            {params.row.features.join(", ")}
          </div>
        );
      },
    },
    { 
      field: "stripeSetupLink", 
      headerName: "Stripe Link",
      width: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.row.stripeSetupLink}
          </div>
        );
      }
    },
    {
      field: "action",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => {
        return (
          <>
           <Link to={"/pricing-packages/" + params.row._id}>
              <button className="productListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="productListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];
  if (isFetching) return <div className="loadingSpinner" >
     <InfinitySpin
          visible={true}
          width="200"
          color="#3949AB"
          ariaLabel="infinity-spin-loading"
        />
  </div>;
  if (error) return <div className="userList">Error fetching Packages</div>;

  return (
    <div className="productList">
      <div className="createButton">
      <Link to="/newprice-package">
          <button className="agreementAddButton">Create New</button>
        </Link>
      </div>
      <DataGrid
        rows={pricingPackages}
        disableSelectionOnClick
        columns={columns}
        getRowId={(row) => row._id}
        pageSize={8}
        autoHeight
        checkboxSelection
        style={{ width: '100%' }}
      />
    </div>
  );
}
