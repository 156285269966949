import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./proposallist.css"; // Assuming you're reusing the same styles from the industry list
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { getProposals, softDeleteProposal } from "../../../redux/apiCalls"; // Adjust path as necessary
import { InfinitySpin } from "react-loader-spinner";
import DeleteModal from "../../../components/DeleteModal/index"; // Adjust the path if necessary

const ProposalList = () => {
  const dispatch = useDispatch();
  const proposalState = useSelector((state) => state.proposals);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [search, setSearch] = useState("");


  useEffect(() => {
    getProposals(dispatch);
  }, [dispatch]);

  if (!proposalState.proposals) {
    return <div className="userList">Proposals array is undefined</div>;
  }

  const { proposals, isFetching, error } = proposalState;

  const handleDelete = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
    setSelectedId(null);
  };

  const handleConfirm = () => {
    softDeleteProposal(selectedId, dispatch);
    setShowModal(false);
    setSelectedId(null);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  
   // Ensure proposals is available before filtering
   const filteredProposals = proposalState.proposals ? proposalState.proposals.filter((proposal) =>
   proposal.brandName.toLowerCase().includes(search.toLowerCase())
 ) : [];
  const columns = [
    {
      field: "brandName",
      headerName: "Brand Name",
      flex: 1,
      renderCell: (params) => (
        <div style={{ 
          fontWeight: '500',
          color: '#000000'
        }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "industry",
      headerName: "Industry",
      width: 150,
      renderCell: (params) => (
        <div style={{
          padding: '4px 12px',
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          height:'40px',
          background: '#f1f5f9',
          borderRadius: '20px',
          fontSize: '12px'
        }}>
          {params.row.industry ? params.row.industry.name : "Not specified"}
        </div>
      ),
    },
    {
      field: "clientType",
      headerName: "Client Type",
      width: 120,
      renderCell: (params) => (
        <div style={{
          fontWeight: '500',
          color: params.value === 'B2B' ? '#0891b2' : '#0d9488'
        }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "packageName",
      headerName: "Package",
      width: 160,
      renderCell: (params) => (
        <div style={{
          padding: '4px 12px',
          background: '#f0f9ff',
          borderRadius: '20px',
          color: '#0369a1',
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          height:'40px',
          fontSize: '12px',
          fontWeight: '500'
        }}>
          {params.row.selectedPackage ? params.row.selectedPackage.name : "Not specified"}
        </div>
      ),
    },
    {
      field: "timeFrame",
      headerName: "Time Frame",
      width: 130,
      renderCell: (params) => {
        let color = '#000000'; // Default color
        if (params.value > 30) {
          color = '#FF0000'; // Red for more than 30 days
        } else if (params.value < 15) {
          color = '#008000'; // Green for less than 15 days
        }
        return (
          <div style={{
            fontWeight: '600',
            color: color
          }}>
            {params.value} Days
          </div>
        );
      },
    },
    {
      field: "signedDate",
      headerName: "Signed Date",
      width: 150,
      renderCell: (params) => (
        <div style={{
          color: params.row.signedDate ? '#00b8e6' : '#000000', // Blue color for signed dates
          fontWeight: params.row.signedDate ? 'bold' : 'normal', // Bold for signed dates
          background: params.row.signedDate ? '#f0f9ff' : 'none',
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          height:'40px', // Light blue background for signed dates
          borderRadius: params.row.signedDate ? '20px' : '0', // Rounded corners for signed dates
          padding: params.row.signedDate ? '4px 12px' : '0', // Padding for signed dates
        }}>
          {params.row.signedDate
            ? new Date(params.row.signedDate).toLocaleDateString()
            : "Not signed"}
        </div>
      ),
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 150,
      renderCell: (params) => (
        <div style={{
          color: '#64748b',
          fontSize: '12px'
        }}>
          {new Date(params.row.createdDate).toLocaleDateString()}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/proposals/" + params.row._id}>
              <button className="userListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];

  if (isFetching)
    return (
      <div className="loadingSpinner">
        <InfinitySpin
          visible={true}
          width="200"
          color="#3949AB"
          ariaLabel="infinity-spin-loading"
        />
      </div>
    );
  if (error) return <div className="userList">Error fetching proposals</div>;

  return (
    <div className="userList">
      {showModal && <DeleteModal onCancel={handleCancel} onConfirm={handleConfirm} />}
      <div className="search">
        <input
          type="text"
          value={search}
          onChange={handleSearchChange}
          placeholder="🔍 Search by Brand Name..."
          className="searchInput"
        />
      </div>
      
      <DataGrid
        rows={filteredProposals}
        getRowId={(row) => row._id}
        columns={columns}
        // pageSize={10}
        rowsPerPageOptions={[10, 25, 50]}
        checkboxSelection
        disableSelectionOnClick
        autoHeight
        loading={isFetching}
      />
    </div>
  );
};

export default ProposalList;
