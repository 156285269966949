import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_URL;

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

const getUserToken = () => {
  try {
    const storedData = localStorage.getItem('user');
    console.log('Stored user data:', storedData); // Log the raw data from local storage
    const token = JSON.parse(storedData)?.accessToken;
    console.log('Token retrieved for request:', token); // Confirm the token is correct each time it's fetched
    return token;
  } catch (error) {
    console.error('Error fetching token:', error);
    return null;
  }
};


export const userRequest = () => {
  const token = getUserToken();
  console.log('Authorization Header being set:', `Bearer ${token}`); // Check the header value
  return axios.create({
    baseURL: BASE_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
};


