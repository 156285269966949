import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  TextField, Button, Box, Typography, FormGroup, FormControlLabel, Checkbox, Grid, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import './newcard.css';

export default function NewStrategy() {
  const initialFormData = {
    content: '',
    strategyType: '',
    industries: [],
    pricingPackage: {_id: '', name: '', pricing:''},
    youtubeVideo: '' 
  };

  const [strategyData, setStrategyData] = useState(initialFormData);
  const [industries, setIndustries] = useState([]);
  const [packages, setPackages] = useState([]);
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const BASE_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios.get(`${BASE_URL}/industries`)
      .then(response => setIndustries(response.data))
      .catch(error => console.error('Error fetching industries:', error));

    axios.get(`${BASE_URL}/pricing-packages`)
      .then(response => setPackages(response.data))
      .catch(error => console.error('Error fetching pricing packages:', error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "pricingPackage") {
        // Find the package that matches the selected ID
        const selectedPackage = packages.find(pkg => pkg._id === value);
        setStrategyData(prev => ({
            ...prev,
            pricingPackage: selectedPackage || {_id: '', name: ''}
        }));
    } else {
        setStrategyData(prev => ({
            ...prev,
            [name]: value
        }));
    }
};


const handleIndustryChange = (event, industry) => {
  const isChecked = event.target.checked;

  setStrategyData(prev => {
    const alreadySelected = prev.industries.some(i => i._id === industry._id);
    if (isChecked && !alreadySelected) {
      // Add the full industry object to the state
      return { ...prev, industries: [...prev.industries, industry] };
    } else if (!isChecked && alreadySelected) {
      // Remove the industry object from the state
      return { ...prev, industries: prev.industries.filter(i => i._id !== industry._id) };
    } else {
      return prev;
    }
  });
};

  const handleEditorChange = (content) => {
    setStrategyData(prev => ({
      ...prev,
      content
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   // Log to verify data

    const formData = new FormData();
    formData.append('content', strategyData.content);
    formData.append('strategyType', strategyData.strategyType);
    formData.append('pricingPackage', JSON.stringify(strategyData.pricingPackage));
    formData.append('youtubeVideo', strategyData.youtubeVideo); 
    formData.append('industries', JSON.stringify(strategyData.industries)); // This should be full objects

    if (fileInputRef.current.files[0]) {
        formData.append('image', fileInputRef.current.files[0]);
    }

    try {
        const response = await axios.post(`${BASE_URL}/strategies`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        alert("Strategy created successfully!");
        console.log("Submitted response:", response.data); // Log response to see what's being returned
        setStrategyData(initialFormData); // Reset form
    } catch (error) {
        console.error('Failed to create strategy:', error);
        alert("Failed to create strategy: " + error.message);
    }
};
  
const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  } else {
    setImagePreview(null);
  }
};

  

  return (
    <div className="formContainer">
      <Box>
        <Typography variant="h4" gutterBottom>
          Create New Strategy
        </Typography>
        <form onSubmit={handleSubmit}>
          <div className="newUserItem">
            <label>Content</label>
            <Editor
              apiKey="3f1a54mgnuot0j04sqjkrk3ett5tsgzr9sblkjixayw6ob6o"
              value={strategyData.content}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
              }}
              onEditorChange={handleEditorChange}
            />
          </div>
          <FormControl fullWidth margin="normal">
      <Button
        variant="contained"
        onClick={() => fileInputRef.current.click()}
        sx={{
          mb: 2,
          backgroundColor: '#1976d2',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#115293',
          },
          padding: '8px 16px',
          fontSize: '14px',
        }}
      >
        Upload Image
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      {imagePreview && (
        <Box
          sx={{
            textAlign: 'center',
            mt: 2,
            border: '2px solid #ddd',
            borderRadius: '8px',
            padding: '4px',
            width: '300px', // Adjust the width to make the preview smaller
            margin: '0 auto', // Center the preview box
          }}
        >
          <img src={imagePreview} alt="Image Preview" style={{ maxWidth: "100%", borderRadius: '8px' }} />
        </Box>
      )}

    </FormControl>
    <FormControl fullWidth margin="normal">
  <TextField
    name="youtubeVideo"
    label="YouTube Video URL"
    value={strategyData.youtubeVideo}
    onChange={handleChange}
    variant="outlined"
    placeholder='Please enter the Youtube video Url'
  />
</FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Strategy Type</InputLabel>
            <Select
              name="strategyType"
              value={strategyData.strategyType}
              label="Strategy Type"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="B2B">B2B</MenuItem>
              <MenuItem value="B2C">B2C</MenuItem>
              <MenuItem value="All">All</MenuItem> 
            </Select>
          </FormControl>
          <Typography margin="normal">Select Industries</Typography>
          <FormGroup>
            <Grid container spacing={2}>
              {industries.map((industry) => (
                <Grid item xs={6} sm={4} key={industry._id}>
                  <FormControlLabel
          control={
            <Checkbox
              checked={strategyData.industries.some(i => i._id === industry._id)}
              onChange={(e) => handleIndustryChange(e, industry)}
              name="industries"
            />
          }
          label={industry.name}
        />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
          <FormControl fullWidth margin="normal">
            <InputLabel id="pricing-package-label">Pricing Package</InputLabel>
            <Select
              labelId="pricing-package-label"
              name="pricingPackage"
              value={strategyData.pricingPackage._id || ""}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {packages.map((pkg) => (
                <MenuItem key={pkg._id} value={pkg._id}>
                  {pkg.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Create Strategy
          </Button>
        </form>
      </Box>
    </div>
  );
}
